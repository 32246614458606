/* styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

nav > div > div > div > div.relative > div {
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  pointer-events: none;
}

nav > div > div > div > div.relative:hover > div {
  transform: scaleY(1);
  opacity: 1;
  pointer-events: auto;
}
